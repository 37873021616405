/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-03-16 20:14:47
 * @LastEditors: pikachu
 * @LastEditTime: 2023-11-28 22:18:10
 */
import Vue from 'vue'
import App from './App.vue'
import '@/util/util.js'
import '@/service/httpService'
import '@/router/routerGuard.js'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/components/index.js'
import '@/service/appJSBridge'
import { Lazyload } from 'vant'
store.commit('initDesignWidthDatum');
Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'wx-open-audio',
]
Vue.use(Lazyload);

// 视频播放组件

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
