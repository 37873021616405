<!--
 * @Descripttion:  pc端预览二维码
 * @version: 
 * @Author: 
 * @Date: 2022-03-19 22:43:37
 * @LastEditors: 白志明
 * @LastEditTime: 2022-03-19 23:36:28
-->
<template>
  <div class="pc_preview">
    <div class="qrcode_container"></div>
    <div class="preview_text_container">
      <div class="title">当前H5页面地址</div>
      <div class="address_container" v-text="curAddress" readonly></div>
      <Button
        class="copy_address_btn"
        :data-clipboard-text="curAddress"
        type="primary"
        size="small"
        >点击复制</Button
      >
    </div>
  </div>
</template>
<script>
import "@/libs/qrcode.js";
import ClipboardJS from "clipboard";
import { Toast } from "vant";
export default {
  name: "pcPreview",
  data() {
    return {
      qrcode: null,
      curAddress: "",
    };
  },
  mounted() {
    this.$watch(
      "$route.path",
      () => {
        this.initQrcode();
      },
      {
        deep: true,
      }
    );
    this.initClipboardJS();
  },
  methods: {
    /**
     * @description: 初始化ClipboardJS
     * @param {*}
     * @return {*}
     */
    initClipboardJS() {
      const clipboard = new ClipboardJS(".copy_address_btn");
      clipboard.on("success", function (e) {
        Toast({
          message: `当前页面地址已成功的复制到剪切板！`,
          type: "success",
          position: "top",
        });
        e.clearSelection();
      });
    },
    /**
     * @description: 初始化二维码
     * @param {*}
     * @return {*}
     */
    initQrcode() {
      this.curAddress = window.location.href;
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(this.curAddress);
      } else {
        this.qrcode = new window.QRCode(
          this.$el.querySelector(".qrcode_container"),
          {
            text: this.curAddress,
            width: 200,
            height: 200,
            colorDark: "#FCD006",
            colorLight: "#ffffff",
            correctLevel: window.QRCode.CorrectLevel.H,
          }
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pc_preview {
  position: fixed;
  display: none;
  width: 200px;

  right: 200px;
  top: 200px;
  .qrcode_container {
    border: 1px solid @primarycolor;
    height: 200px;
  }
  .preview_text_container {
    margin-top: 0.2rem;

    .title {
      color: @gray-8;
      font-size: 0.15rem;
      margin-bottom: 0.2rem;
    }
    .address_container {
      border: 1px solid @primarycolor;
      border-radius: @border-radius-lg;
      padding: 0.1rem;
      margin-bottom: 0.2rem;
    }
  }
}
@media screen and (min-width: 1024px) {
  .pc_preview {
    display: block;
  }
}
</style>