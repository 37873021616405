<!--
 * @Descripttion:  通用检索框
 * @version: 
 * @Author: 
 * @Date: 2022-03-21 12:32:27
 * @LastEditors: pikachu
 * @LastEditTime: 2023-01-12 16:41:02
-->
<template>
  <div class="comomon_search">
    <!-- <div class="common_search_inner" :style="innerStyle"> -->
    <div class="common_search_inner">
      <Search
        v-model="searchwordInner"
        @search="onSearch"
        @clear="clearSearchword"
        :placeholder="searchToast"
      >
        <!-- <template #right-icon>
          <div v-if="isWxBrowser" class="search_right_icons">
            <label @click="scan" class="hmufont hmu-saoma"></label>
            <label @click="voiceToText" class="hmufont hmu-yuyin"></label>
          </div>
        </template>-->
      </Search>
    </div>
  </div>
</template>
<script>
import { scan, voiceToText } from "@/service/appJSBridge";
import { isWxBrowser } from "@/service/commonService";
export default {
  name: "commonSearch",
  model: {
    prop: "searchword",
    event: "change",
  },
  props: {
    searchword: {
      type: String,
      default: "",
    },
    searchToast: {
      type: String,
      default: "书名",
    },
  },
  data() {
    return {
      innerDomWidth: 0,
      innerDomLeft: 0,
      innerDomTop: 0,
      isWxBrowser: isWxBrowser(),
      innerDomBackgroundColor: "",
    };
  },
  computed: {
    /**
     * @description: 内部的检索词
     * @param {*}
     * @return {*}
     */
    searchwordInner: {
      get: function () {
        return this.searchword;
      },
      set: function (newV) {
        this.$emit("change", newV);
      },
    },
    /**
     * @description: 内部dom样式
     * @param {*}
     * @return {*}
     */
    innerStyle() {
      return {
        width: `${this.innerDomWidth}px`,
        left: `${this.innerDomLeft}px`,
        top: `${this.innerDomTop}px`,
        backgroundColor: this.innerDomBackgroundColor,
      };
    },
  },
  methods: {
    /**
     * @description: 语音转文字
     * @param {*}
     * @return {*}
     */
    async voiceToText() {
      const text = await voiceToText();
      this.$emit("change", text);
      this.$emit("search", this.searchword);
    },
    /**
     * @description: 检索事件
     * @param {*}
     * @return {*}
     */
    onSearch() {
      this.$emit("search", this.searchword);
    },
    /**
     * @description: 清空检索词
     * @param {*}
     * @return {*}
     */
    clearSearchword() {
      this.$emit("change", "");
      this.$emit("search", "");
    },
    /**
     * @description: 初始化内部dom样式
     * @param {*}
     * @return {*}
     */
    initInnerDomStyle() {
      const { x, width } = this.$el.getBoundingClientRect();
      const { backgroundColor: innerDomBackgroundColor } = getComputedStyle(
        this.$el
      );
      let { borderTopWidth } = getComputedStyle(document.querySelector("#app"));
      borderTopWidth = Number(borderTopWidth.replace(/px/, ""));
      this.innerDomWidth = width;
      this.innerDomLeft = x;
      this.innerDomTop = borderTopWidth;
      this.innerDomBackgroundColor = innerDomBackgroundColor;
    },
    /**
     * @description: 扫一扫
     * @param {*}
     * @return {*}
     */
    scan() {
      scan();
    },
  },
  mounted() {
    this.initInnerDomStyle();
    window.addEventListener("resize", this.initInnerDomStyle);
  },
  destroyed() {
    window.removeEventListener("resize", this.initInnerDomStyle);
  },
};
</script>
<style lang="less" scoped>
.comomon_search {
  // height: 0.6rem;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0.01rem;
  .common_search_inner {
    // padding-top: inherit;
    padding-bottom: inherit;
    box-sizing: inherit;
    height: inherit;
    top: 0;
    position: relative;
    width: 100%;
    z-index: 2;
    .search_right_icons {
      label {
        font-size: 0.25rem;
        cursor: pointer;
        color: #999;
      }
      label + label {
        margin-left: 0.04rem;
      }
    }
  }
}
</style>
<style lang="less">
.comomon_search {
  border-radius: 0.1rem;
  .common_search_inner {
    border-radius: 0.1rem;
    .van-search {
      border-radius: 0.1rem;
      .van-search__content {
        border-radius: 0.1rem;
      }
    }
    .van-icon-search {
      position: relative;
      top: 0.02rem;
    }
    .hmu-saoma {
      position: relative;
      top: 0.01rem;
    }
  }
}
</style>