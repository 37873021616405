/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-03-22 17:12:21
 * @LastEditors: pikachu
 * @LastEditTime: 2022-12-23 23:32:44
 */
import router from "./index.js"
import store from '@/store'
import { initWxRelated, isWxBrowser, wxAutoLogin, wxAutoRegister } from '@/service/commonService'
import { getToken } from '@/service/httpService'

import Vue from "vue";

const loginInterceptionPages = [
  'bindingWechat',
  'bindingMobile'
];  // 登录拦截的页面名单
router.beforeEach(async (to, from, next) => {
  initWxRelated({ debuggerMode: false });
  let userInfo
  if (isWxBrowser()) {   // 如果是微信环境的浏览器
    if (to.name === 'login') { // 禁止进入登录页
      router.replace2({
        name: 'home'
      })
      return;
    }
    if (!getToken() || to.query.code) {
      await wxAutoLogin(to, router); // 微信自动登录逻辑
    }
    // await wxAutoLogin(to, router); // 微信自动登录逻辑
    // userInfo = await store.dispatch('getUserInfo').catch(() => { });
    // if (userInfo.registerStatus === 0 && loginInterceptionPages.includes(to.name)) {
    //   const toHref = router.resolve(to).href;
    //   history.replaceState(null, null, toHref);
    //   wxAutoRegister(router);
    //   return;
    // }
  } else {
    userInfo = await store.dispatch('getUserInfo').catch(() => { });
  }
  if (!userInfo) {
    if (loginInterceptionPages.includes(to.name)) {
      // const address = router.resolve({
      //   name: 'login',
      //   query: {
      //     beforeAddress: window.location.href
      //   }
      // }).href;
      // window.location.replace(address);
      next()
    } else {
      // document.title = to.meta.title
      document.title = "\u200E"
      next();
      /*       setTimeout(() => {
              initWxRelated({ debuggerMode: false });
            }, 500); */
    }
  } else {
    if (to.name === 'login') {
      const beforeAddress = to.query.beforeAddress;
      if (!beforeAddress) {
        window.location.replace('/home');
      } else {
        window.location.replace(beforeAddress);
      }
    } else {
      // document.title = to.meta.title
      document.title = "\u200E"
      next();
      /*       setTimeout(() => {
              initWxRelated({ debuggerMode: false });
            }, 500); */
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const userInfo = await store.dispatch('getUserInfo').catch(() => { });
  let oldUserId = GetQueryString("userId")
  if (!userInfo) { return next() }
  if (oldUserId == userInfo.id) { next(); }
  else if (oldUserId == null) {
    if (to.query.state) {
      delete to.query.state
    }
    if (to.query.from) {
      delete to.query.from
    }
    const address = router.resolve({
      name: to.name,
      query: {
        ...to.query, userId: userInfo.id, timeStamp: Date.now()
      }
    }).href;
    Vue.prototype.source.cancel('跳转取消')
    window.location.replace(address);
  } else if (oldUserId != userInfo.id) {
    if (to.query.state) {
      delete to.query.state
    }
    if (to.query.from) {
      delete to.query.from
    }
    // Vue.prototype.post("/share/save", { shareUrl: window.location.href, oldUserId }).then(r => {
    const address = router.resolve({
      name: to.name,
      query: {
        ...to.query, userId: userInfo.id
      }
    }).href;
    Vue.prototype.source.cancel('跳转取消')
    window.location.replace(address);
    // })
  } else {
    next()
  }
});

router.afterEach((to, from, next) => {
  let uuid = localStorage.getItem('uuid')
  console.log(uuid)
  if (uuid == null) {
    uuid = createRandomString()[0]
    localStorage.setItem('uuid', uuid)
  }
  let fromUrl = from.fullPath != '/' ? from.fullPath : document.referrer
  Vue.prototype.post("/page/log", {
    fromUrl: fromUrl,
    toUrl: to.fullPath,
    uuid,
  })
  Vue.prototype.get("/notice/noRead").then(r => {
    store.commit('setNewMsgNub', r)
  })
})

function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

function createRandomString(count = 1) {
  // 随机生成uuid
  const res = new Set();
  for (let i = 0; i < count; i++) {
    // 获取5位有效随机整数
    const random = parseInt(Math.random().toString().substring(2), 10)
      .toString()
      .substring(0, 5);
    // 获取时间戳
    const now = Date.now();
    // 生成拼接数字字串
    const code = parseInt(random + now.toString() + i.toString(), 10)
      .toString(36)
      .toUpperCase();
    // 如果重复, 再生成一次
    if (res.has(code)) {
      i--;
      continue;
    }
    // 否则插入结果列表
    res.add(code);
  }
  // 返回结果
  return Array.from(res);
}