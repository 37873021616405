/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-04-05 00:49:38
 * @LastEditors: 白志明
 * @LastEditTime: 2022-04-28 00:52:45
 */
import { isWxBrowser, getBrowserInfo } from './commonService'
import { ImagePreview } from 'vant'
import Vue from 'vue'
import "@/libs/qrcode.js";
import { Toast, Dialog } from 'vant';
/**
 * @description: 设置分享内容
 * @param {*}
 * @return {*}
 */
export async function setShare({
  title,  // 标题
  desc,   // 描述
  link,   // 链接
  imgUrl, // 图片
} = {}) {
  title = title ? title : '滋味书屋';
  desc = desc ? desc : '来自大申科技';
  link = link ? link : window.location.href;
  imgUrl = imgUrl ? imgUrl : `${window.location.origin}/logo.png`;
  if (!isWxBrowser()) {
    return;
  }
  await window.wxSdkConfigPromise;
  window.wx.updateAppMessageShareData({
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl, // 分享图标
    success: function () {
      console.log('分享朋友设置成功');
    }
  });
  window.wx.updateTimelineShareData({
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl, // 分享图标
    success: function () {
      console.log('分享朋友圈设置成功');
    }
  });
  window.wx.onMenuShareWeibo({
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接
    imgUrl, // 分享图标
    success: function () {
      console.log('分享到微博成功');
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      console.log('分享到微博操作取消');
      // 用户取消分享后执行的回调函数
    }
  });
}
Vue.prototype.setShare = setShare;
/**
 * @description: 预览图片功能
 * @param {*} options
 * @param {*} index
 * @return {*}
 */
export async function previewImage({ imgUrls, index } = {}) {
  if (!(Array.isArray(imgUrls)) || imgUrls.length === 0) {
    return;
  }
  index = isNaN(index) ? 0 : index;
  index = index < 0 ? 0 : index;
  index = index >= imgUrls.length ? (imgUrls.length - 1) : index;
  if (!isWxBrowser()) {
    ImagePreview({
      images: imgUrls,
      startPosition: index,
    })
  } else {
    await window.wxSdkConfigPromise;
    window.wx.previewImage({
      current: imgUrls[index], // 当前显示图片的http链接
      urls: imgUrls // 需要预览的图片http链接列表
    });
  }
}
Vue.prototype.previewImage = previewImage;
/**
 * @description: 扫一扫
 * @param {*}
 * @return {*}
 */
export async function scan() {
  await window.wxSdkConfigPromise;
  const browserInfo = getBrowserInfo();
  const isWxMobile =
    browserInfo.app === "weixin";
  if (isWxMobile) {
    window.wx.scanQRCode({
      needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (/* res */) {
        /* var result = res.resultStr; */ // 当needResult 为 1 时，扫码返回的结果
      }
    });
  }
}

/**
 * @description: 语音转文字
 * @param {*}
 * @return {*}
 */
export async function voiceToText() {
  if (!isWxBrowser()) {
    Toast({
      message: `该环境暂不支持该功能!`,
      type: "warning",
      duration: 2e3,
      position: "top",
    });
  }
  window.wx.startRecord();
  await Dialog({
    message: '正在录音中,请说出您要检索的关键词...',
    confirmButtonText: '停止',
  });
  return new Promise((resolve) => {
    window.wx.stopRecord({
      success: function (res) {
        const localId = res.localId;
        window.wx.translateVoice({
          localId, // 需要识别的音频的本地Id，由录音相关接口获得
          isShowProgressTips: 1, // 默认为1，显示进度提示
          success: function (res) {
            let result = res.translateResult;
            result = result.replace(/。$/, '');
            resolve(result);
          }
        });
      }
    });
  });
}