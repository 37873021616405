<!--
 * @Descripttion: 评论弹窗
 * @version: 
 * @Author: 
 * @Date: 2022-03-26 16:23:38
 * @LastEditors: pikachu
 * @LastEditTime: 2022-04-28 15:25:32
-->
<template>
  <div class="comment_modal">
    <cell title="评论/回复" title-class="title"></cell>
    <Form ref="form">
      <Field
        @keydown.enter="submit"
        v-model="commentContent"
        type="textarea"
        rows="6"
        placeholder="随便说点什么吧～"
      ></Field>
      <div class="bottom">
        <Button @click.stop="submit" size="small" type="primary">提交</Button>
        <Button @click.stop="cancel" size="small">取消</Button>
      </div>
    </Form>
  </div>
</template>
<script>
import { Form, Field, Button, Cell } from "vant";
export default {
  name: "commentModal",
  components: {
    Cell,
    Form,
    Field,
    Button,
  },
  data() {
    return {
      commentContent: "",
    };
  },
  methods: {
    /**
     * @description: 提交
     * @param {*}
     * @return {*}
     */
    async submit() {
      await this.$refs.form.validate();
      this.$emit("submit", this.commentContent);
    },
    /**
     * @description: 取消
     * @param {*}
     * @return {*}
     */
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style lang='less' scoped>
.comment_modal {
  width: 3.4rem;
  height: 3rem;
  border-radius: 0.1rem !important;
  .title {
    font-size: 0.16rem;
  }
  form {
    width: 100%;
    height: calc(100% - 0.5rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.2rem 0.2rem 0 0.2rem;
    box-sizing: border-box;
    .van-field {
      background: @gray-2;
    }
    .bottom {
      width: 100%;
      display: flex;
      padding: 0 0.2rem;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      button + button {
        margin-left: 0.2rem;
      }
      button {
        border-radius: 0.05rem;
        height: 0.25rem;
      }
    }
  }
}
</style>