<!--
 * @Author: pikachu
 * @Date: 2022-05-06 14:28:53
 * @LastEditors: pikachu
-->
<template>
  <div :class="['head_bar',barStatus?'head_bar_other':'head_bar_pro']" v-if="showTitle">
    <div class="back_button">
      <img src="@/assets/home_logo.png" v-if="$route.name=='home'" />
      <img src="@/assets/back.png" @click="goBack()" v-show="!showBack" v-if="$route.name!='home'" />
<!--      <img class="home_icon" src="@/assets/home.png" @click="goHome()" v-show="showBack" />-->
    </div>
    <div class="search_box_item" v-if="showSearchButton" @click="jumpSearch">
      <div class="toast_text">点击前往搜索页</div>
      <!-- <img class="search_img" src="@/assets/search.png" @click="showSearchMenu = true" /> -->
    </div>
    <div class="head_name" v-else>{{title?title:$route.meta.title}}</div>
    <div class="rigbt_box">
      <div class="right_img" v-if="openSetting">
        <img src="@/assets/setting.png" @click="goSetting()" />
      </div>
      <div v-else class="right_img">
        <img src="@/assets/user.png" @click="goUser()" />
        <div class="new_info_nub" v-if="msgNub">{{msgNub}}</div>
      </div>
    </div>
    <!-- <div ref="menuButton" class="menu_button" v-if="showMenuButton">
      <img src="@/assets/menu.png" @click="showMenu = true" />
    </div>-->
    <!-- <div ref="menuButton" class="search_button" v-if="showSearchButton">
      <div class="all_button" v-show="showAll" @click="getAll()">全部</div>
      <img class="search_img" src="@/assets/search.png" @click="showSearchMenu = true" />
    </div>
    <div class="none_box" v-else></div>-->
    <Popup
      v-model="showSearchMenu"
      position="top"
      :style="{ height: '0.55rem' }"
      class="search_box"
    >
      <Search v-model="searchValue" placeholder="请输入搜索关键词" @search="onSearch" />
    </Popup>
    <!-- <Popup
      ref="menuBox"
      v-model="showMenu"
      position="right"
      :style="{ height: '30%' }"
      @opened="getMenuLocation()"
    >
      <div class="route_list" v-show="showListcontent">
        <div class="triangle"></div>
        <div
          class="route_name"
          v-for="(item,i) in btList"
          :key="i"
          @click="jumpPage(item)"
        >{{item.name}}</div>
      </div>
    </Popup>-->
  </div>
</template>
<script>
import { Popup, Search } from "vant";

export default {
  name: "headBar",
  components: {
    Popup,
    Search,
  },
  props: {
    showMenuButton: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    showBack: {
      default: true,
      type: Boolean,
    },
    showSearchButton: {
      default: false,
      type: Boolean,
    },
    barStatus: {
      default: true,
      type: Boolean,
    },
    openSetting: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showMenu: false,
      btList: [
        { name: "发现 ", value: "home" },
        { name: "我的", value: "me" },
        { name: "专题", value: "topic" },
        { name: "日签", value: "dailySignature" },
        // { name: "", value: "" },
      ],
      showTitle: true,
      showListcontent: false,
      showSearchMenu: false,
      searchValue: "",
      showAll: false,
      msgNub: JSON.parse(localStorage.getItem("humNewMessages")),
    };
  },
  computed: {
    // msgNub() {
    //   return this.$store.getters.newMessages.nub;
    // },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/posterDetail") {
        this.showTitle = false;
      } else {
        this.showTitle = true;
      }
    },
  },
  methods: {
    /**
     * @description: 设置
     * @param {*}
     * @return {*}
     */
    goSetting() {
      this.$router.push2({
        name: "mySetting",
      });
    },
    goUser() {
      this.$router.push2({
        name: "me",
      });
    },
    jumpSearch() {
      // 搜索
      this.$router.push2({
        name: "search",
      });
      // this.$emit("search", this.searchValue);
    },
    async goHome() {
      // 首页
      await this.get("/page/save", {
        dataType: 8,
      }).finally(() => {
        this.$router.push2({
          name: "home",
        });
      });
    },
    getAll() {
      this.searchValue = "";
      this.onSearch();
    },
    onSearch() {
      // 搜索
      if (this.searchValue) {
        this.showAll = true;
      } else {
        this.showAll = false;
      }
      this.$emit("search", this.searchValue);
      this.showSearchMenu = false;
    },
    onCancel() {},
    /*获取菜单位置*/
    getMenuLocation() {
      let menu = this.$refs.menuButton;
      let menuBox = this.$refs.menuBox.$el;
      let scale = document.body.clientWidth / 375;
      menuBox.style.left = `${
        menu.getBoundingClientRect().left - (45 + scale)
      }px`;
      this.showListcontent = true;
    },
    /*页面返回*/
    goBack() {
      if (this.$route.path == "/home") {
        this.$router.push2({
          name: "dailySignature",
        });
      } else if (this.$route.path == "/ebookReader") {
        this.$router.push2({
          name: "ebookDetail",
          query: {
            id: this.$route.query.id,
          },
        });
      } else {
        window.history.back();
      }
    },
    /*页面跳转*/
    jumpPage(item) {
      this.showMenu = false;
      this.showListcontent = false;
      this.$router.push2({
        name: item.value,
      });
    },
  },
};
</script>
<style scoped lang='less'>
.head_bar_other {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.4rem;
  // border-bottom: 0.01rem solid #f3f3f3;
  width: 100%;
  background: #fff;
  padding: 0.15rem 0;
}
.head_bar_pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.4rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  padding: 0.15rem 0;
  position: fixed;
  top: 0;
  z-index: 5;
}
.head_bar {
  .rigbt_box {
    margin-right: 0.17rem;
    .right_img {
      position: relative;
      img {
        width: 0.28rem;
        height: 0.28rem;
        position: relative;
        top: 0.01rem;
      }
      .new_info_nub {
        font-size: 0.1rem;
        position: absolute;
        right: -0.05rem;
        top: -0.05rem;
        background: #ff0000;
        border-radius: 1rem;
        color: #ffffff;
        width: 0.14rem;
        height: 0.14rem;
        line-height: 0.14rem;
        text-align: center;
      }
    }
  }
  .search_box_item {
    display: flex;
    font-size: 0.135rem;
    align-items: center;
    color: #787878;
    // border: 0.01rem solid #ccc;
    border-radius: 0.2rem;
    padding: 0.05rem 0.25rem;
    background: #e8e8e8;
    .toast_text {
      margin-right: 0.05rem;
      color: #ccc;
    }
    .search_img {
      width: 0.16rem !important;
      height: 0.16rem;
    }
  }
  .back_button {
    margin-left: 0.17rem;
    .home_icon {
      height: 0.3rem;
      width: 0.28rem;
    }
    img {
      height: 0.3rem;
      width: 0.25rem;
    }
  }
  .head_name {
    font-size: 0.13rem;
    color: #666;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .none_box {
    width: 0.3rem;
  }
  .menu_button {
    margin-right: 0.1rem;
  }
  .search_button {
    margin-right: 0.1rem;
    display: flex;
    align-items: center;
    .all_button {
      margin-right: 0.1rem;
      font-size: 0.13rem;
      color: @primarycolor;
    }
    .search_img {
      position: relative;
      top: -0.01rem;
      width: 0.2rem;
    }
  }
}
</style>
<style lang='less'>
@media screen and (min-width: 1024px) {
  .head_bar_pro {
    width: 422px !important;
  }
}
</style>
