<!--
 * @Descripttion: 悬浮播放控件
 * @version: 
 * @Author: 
 * @Date: 2022-05-15 15:19:01
 * @LastEditors: pikachu
 * @LastEditTime: 2023-05-30 20:41:12
-->
<template>
  <div
    :style="suspendedPlayerStyle"
    :key="playingAudio && playingAudio.audioSrc"
    ref="playerContainer"
    @mousedown="watchTouchstart"
    @touchstart="watchTouchstart"
    class="suspended_player"
    :class="{ show: detailHref !== nowHref && playingAudio && playingAudio.show }"
  >
    <div class="btn_container cover" @click="goToDetail">
      <img :src="playingAudio && playingAudio.coverUrl" />
    </div>
    <div
      v-if="playingAudio"
      :style="btnStyle"
      class="btn_container play_btn"
      @click="playOrPauseVoice"
    >
      <img :src="playingAudio && playingAudioBtn" />
      <!-- <wx-open-audio v-else id="audio" :title="playingAudio.audioTitle" :episode="playingAudio.audioEpisode"
        :cover="playingAudio.coverUrl" :src="playingAudio.audioSrc" singer="滋味书屋">
        <script type="text/wxtag-template">
          <div class="pause"></div>
      </script>
        <script type="text/wxtag-template" slot="playing">
          <div class="playing"></div>
      </script>
        <script type="text/wxtag-template" slot="style">
          <style>
    .playing{
      width:{{btnSizePx}}px;
      height:{{btnSizePx}}px;
      background:url("{{pauseBtn}}");
      background-size:100%;
      background-repeat:no-repeat;
      cursor:pointer;
    }
    .pause {
      width: {{btnSizePx}}px;
      height: {{btnSizePx}}px;
      cursor: pointer;
      background:url("{{playBtn}}");
      background-size:100%;
      background-repeat:no-repeat;
      cursor:pointer;
    }
  </style>
      </script>
      </wx-open-audio>-->
    </div>
    <div
      :style="btnStyle"
      @click="closeAudio"
      class="btn_container close_audio"
      v-if="playingAudio && playingAudio.status === 'pause'"
    >
      <img :src="closeBtn" />
    </div>
  </div>
</template>
<script>
import closeBtn from "@p/img/close.png";
import { isWxMobileBrowser } from "@/service/commonService";
export default {
  name: "suspendedPlayer",
  props: {
    playBtn: {
      type: String,
      default: `${window.origin}/img/blackPlayBtn.png`,
    },
    pauseBtn: {
      type: String,
      default: `${window.origin}/img/yellowStopBtn.png`,
    },
    btnSize: {
      type: Number, // 按钮的尺寸， 单位为rem
      default: 0.3,
    },
  },
  computed: {
    btnStyle() {
      return {
        width: `${this.btnSize}rem`,
        height: `${this.btnSize}rem`,
      };
    },
    btnSizePx() {
      if (this.htmlFontSize === 0) {
        return 30;
      }
      return Math.round(this.htmlFontSize * this.btnSize);
    },
    playingAudio() {
      return this.$store.getters.playingAudio;
    },
    playingAudioStatus() {
      return this.playingAudio.status;
    },
    playingAudioBtn() {
      return this.playingAudioStatus === "playing"
        ? this.pauseBtn
        : this.playBtn;
    },
    voiceType() {
      return this.playingAudio.voiceType;
    },
    detailHref() {
      if (!this.playingAudio) {
        return "";
      }
      return this.$router.resolve({
        name: this.jumpRouteName,
        query: {
          id: this.playingAudio.id,
        },
      }).href;
    },
    jumpRouteName() {
      return this.voiceType === "poster" ? "posterDetail" : "voiceDetail";
    },
    nowHref() {
      return this.$route.fullPath;
    },
    suspendedPlayerY() {
      return this.$store.getters.suspendedPlayerY;
    },
    isWxMobileBrowser() {
      return isWxMobileBrowser();
    } /* ,
    suspendedPlayerStyle() {
      if (this.$store.getters.suspendedPlayerY === null) {
        return {}
      } else {
        return {
          top: `${this.$store.getters.suspendedPlayerY}px`
        }
      }
    }, */,
  },
  data() {
    return {
      closeBtn,
      offsetY: 0,
      touchStartPlayerY: 0,
      htmlFontSize: 0,
      audioSrc: "",
      touchStartPageX: 0,
      touchStartPageY: 0,
      playerHeight: 0,
      dragging: false,
      audioDom: null,
      outerContainerTop: 0,
      suspendedPlayerStyle: {},
    };
  },
  mounted() {
    this.htmlFontSize = Number(
      getComputedStyle(document.documentElement).fontSize.replace("px", "")
    );
    window.addEventListener("mouseup", this.watchGlobalTouchend);
    window.addEventListener("touchend", this.watchGlobalTouchend);
    window.addEventListener("touchmove", this.watchGlobalTouchmove);
    window.addEventListener("mousemove", this.watchGlobalTouchmove);
    const { height: playerHeight } =
      this.$refs.playerContainer.getBoundingClientRect();
    this.playerHeight = playerHeight;
    this.watchSuspendedPlayerY();
    this.watchAudioDom();
  },
  destroyed() {
    const self = this;
    window.removeEventListener("mouseup", this.watchGlobalTouchend);
    window.removeEventListener("touchend", this.watchGlobalTouchend);
    window.removeEventListener("touchmove", this.watchGlobalTouchmove);
    window.removeEventListener("mousemove", this.watchGlobalTouchmove);
    if (self.audioDom) {
      self.audioDom.removeEventListener("ended", self.audioEnded);
      self.audioDom.removeEventListener("timeupdate", self.audioTimeupdate);
    }
  },

  methods: {
    /**
     * @description: 监控音频dom
     * @return {*}
     */
    watchAudioDom() {
      const self = this;
      self.$watch(
        "playingAudio.id",
        (newV, oldV) => {
          if (!newV) {
            return;
          }
          if (newV === oldV) {
            return;
          }
          if (self.audioDom) {
            self.audioDom.removeEventListener("ended", self.audioEnded);
            self.audioDom.removeEventListener(
              "timeupdate",
              self.audioTimeupdate
            );
          }
          self.audioDom = self.playingAudio.audioDom;
          self.audioDom.addEventListener("ended", self.audioEnded);
          self.audioDom.addEventListener("timeupdate", self.audioTimeupdate);
        },
        {
          immediate: true,
        }
      );
    },
    /**
     * @description: 监听音频播放结束
     * @return {*}
     */
    audioEnded() {
      this.$store.commit("setPlayingAudio", null);
    },
    /**
     * @description: 监听音频时间更新
     * @return {*}
     */
    audioTimeupdate() {
      this.$store.commit("setPlayingAudio", {
        currentTime: this.audioDom.currentTime,
      });
    },
    /**
     * @description: 监控vuex中 suspendedPlayerY的变化
     * @return {*}
     */
    watchSuspendedPlayerY() {
      this.$watch(
        "suspendedPlayerY",
        (newV) => {
          if (newV === null) {
            this.suspendedPlayerStyle = {};
          } else {
            const outerContainer = this.$refs.playerContainer.parentElement;
            let { borderBottomWidth, height } =
              getComputedStyle(outerContainer);
            borderBottomWidth = Number(borderBottomWidth.replace("px", ""));
            height = Number(height.replace("px", ""));
            newV = newV < 0 ? 0 : newV;
            const maxHeight = height - borderBottomWidth - this.playerHeight;
            newV = newV > maxHeight ? maxHeight : newV;
            this.suspendedPlayerStyle = {
              top: `${newV}px`,
            };
          }
        },
        {
          immediate: true,
        }
      );
    },
    /**
     * @description: 监听全局拖拽移动事件
     * @return {*}
     */
    watchGlobalTouchmove(e) {
      if (!this.dragging) {
        return;
      }
      const { pageY } = e.type === "touchmove" ? e.touches[0] : e;
      const diffreent = this.touchStartPlayerY - (pageY - this.offsetY);
      const suspendedPlayerY = this.touchStartPlayerY - diffreent;
      this.$store.commit("updateSuspendedPlayerY", suspendedPlayerY);
    },
    /**
     * @description: 监听全局拖动结束事件
     * @return {*}
     */
    watchGlobalTouchend() {
      this.dragging = false;
    },
    /**
     * @description: 监听按下事件
     * @param undefined
     * @return {*}
     */
    watchTouchstart(e) {
      const { pageY } = e.type === "touchstart" ? e.touches[0] : e;
      const { y: playerY, height: playerHeight } =
        this.$refs.playerContainer.getBoundingClientRect();
      const outerContainer = this.$refs.playerContainer.parentElement;
      let { top } = outerContainer.getBoundingClientRect();
      this.playerHeight = playerHeight;
      this.touchStartPlayerY = playerY;
      this.offsetY = pageY - playerY + top;
      this.dragging = true;
    },
    /**
     * @description: 进入声音详情页
     * @return {*}
     */
    goToDetail() {
      let { id, type } = this.playingAudio;
      if (this.jumpRouteName == "posterDetail") {
        this.$router.push2({ name: "posterDetail", query: { id } });
      } else {
        switch (type) {
          case "2":
            this.$router.push2({ name: "audioBookDetail", query: { id } });
            break;
          case "3":
            this.$router.push2({ name: "audioCourseDetail", query: { id } });
            break;
          case "1":
            this.$router.push2({ name: "audioPodcastDetail", query: { id } });
            break;
          // case "4":
          //   this.$router.push2({ name: "audioRadio", query: { id } });
          //   break;
          default:
            break;
        }
      }
    },
    /**
     * @description: 播放或暂停音频
     * @return {*}
     */
    playOrPauseVoice() {
      if (!this.playingAudio) {
        return;
      }
      if (this.playingAudio.status === "pause") {
        this.playingAudio.audioDom.play();
        this.$store.commit("setPlayingAudio", {
          status: "playing",
        });
      } else {
        this.playingAudio.audioDom.pause();
        this.$store.commit("setPlayingAudio", {
          status: "pause",
        });
        let end = setInterval(function () {}, 10);
        for (let i = 1; i <= end; i++) {
          // 取消所有定时任务-可优化
          clearInterval(i);
        }
      }
    },
    /**
     * @description: 关闭音频
     * @return {*}
     */
    closeAudio() {
      this.$store.commit("setPlayingAudio", null);
    },
  },
};
</script>
<style lang="less" scoped>
.suspended_player {
  user-select: none;
  position: absolute;
  left: 0;
  top: calc(100% - 4rem);
  z-index: 99;
  background: white;
  height: 0.52rem;
  box-shadow: 0 0 0.12rem 0 rgba(0, 0, 0, 0.6);
  border-top-right-radius: 0.26rem;
  border-bottom-right-radius: 0.26rem;
  padding: 0.1rem;
  box-sizing: border-box;
  cursor: all-scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: initial;
  }

  .btn_container {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    &.cover {
      width: 0.3rem;
      height: 100%;
      cursor: pointer;
    }

    &.play_btn,
    &.close_audio {
      width: 0.3rem;
      height: 0.3rem;
      cursor: pointer;
    }
  }

  .btn_container + .btn_container {
    margin-left: 0.1rem;
  }
}
</style>