<template>
  <div
    class="loaders"
    :style="myStyle"
    :class="{ small_range: smallRange }"
    v-if="showLoader"
  >
    <div class="inner">
      <div class="animations load1">
        <div class="loader"></div>
      </div>
      <div class="text" v-text="tip"></div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: "loaders",
  data() {
    return {
      showLoader: false,
      promise: "",
    };
  },
  props: {
    loader: {},
    tip: {
      type: String,
      default: "数据加载中，请稍候...",
    },
    smallRange: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
  },
  mounted() {
    let self = this;
    if (this.smallRange) {
      setTimeout(() => {
        let $parent = $(self.$el).parent().eq(0);
        $parent.css("position", "relative");
        // $(self.$el).css("position", "absolute");
      }, 600);
    }
    window.addEventListener("keydown", this.keyboardEvent);
  },
  computed: {
    myStyle() {
      return {
        "z-index": this.zIndex,
      };
    },
  },
  methods: {
    /**
     * [keyboardEvent description] 键盘支持，退出蒙层
     * @return {[type]} [description]
     */
    keyboardEvent(event) {
      if (event.keyCode === 27 && this.showLoader === true) {
        this.showLoader = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyboardEvent);
  },
  watch: {
    loader(newV) {
      if (this.promise) {
        clearTimeout(this.promise);
        this.promise = null;
      }
      if (newV) {
        this.showLoader = true;
        this.promise = newV.then(
          () => {
            this.showLoader = false;
          },
          () => {
            this.showLoader = false;
          }
        );
      }
      return this.promise;
    },
  },
};
</script>
<style lang="less" scoped>
.loaders {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;

  &.small_range {
    position: absolute;
  }

  .inner {
    height: max-content;
    width: max-content;
    .text {
      opacity: 0.6;
      display: inline-block;
      color: black;
      font-size: 0.12rem;
    }
  }
}

@import "./load.less";
</style>