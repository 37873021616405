/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-03-16 20:14:47
 * @LastEditors: pikachu
 * @LastEditTime: 2023-11-29 23:16:25
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import rewriteRouter from './reWriteRouter'
import store from '@/store'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

rewriteRouter(VueRouter);

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '搜索结果'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/newHome_4.vue'),
    meta: {
      title: '发现'
    }
  },
  {
    path: '/topic',
    name: 'topic',
    component: () => import('@/views/topic/topicHome.vue'),
    meta: {
      title: '专题'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录页'
    }
  }, {
    path: '/topicDetail',
    name: 'topicDetail',
    component: () => import('@/views/topicDetail/topicDetail2.vue'),
    meta: {
      title: '专题详情页'
    }
  }, {
    path: '/me',
    name: 'me',
    component: () => import('@/views/me/newIndex.vue'),
    meta: {
      title: '我'
    }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('@/views/me/orderList.vue'),
    meta: {
      title: '订单列表'
    }
  },
  {
    path: '/notesList',
    name: 'notesList',
    component: () => import('@/views/me/notesList.vue'),
    meta: {
      title: '笔记列表'
    }
  },
  {
    path: "/msgList",
    name: 'msgList',
    component: () => import('@/views/me/msgList.vue'),
    meta: {
      title: '消息列表'
    }
  },
  {
    path: "/historyList",
    name: 'historyList',
    component: () => import('@/views/me/historyList.vue'),
    meta: {
      title: '最近在看'
    }
  },
  {
    path: '/membershipCardList',
    name: 'membershipCardList',
    component: () => import('@/views/membershipCard/membershipCardList.vue'),
    meta: {
      title: '会员'
    }
  },
  {
    path: '/openedCard',
    name: 'openedCard',
    component: () => import('@/views/membershipCard/openedCard.vue'),
    meta: {
      title: '开通会员'
    }
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import('@/views/me/components/gift.vue'),
    meta: {
      title: '礼物'
    }
  },
  {
    path: '/dailySignature',
    name: 'dailySignature',
    component: () => import('@/views/dailySignature/index.vue'),
    meta: {
      title: '日签'
    }
  },
  {
    path: '/mySetting',
    name: 'mySetting',
    component: () => import('@/views/mySetting/index.vue'),
    children: [{
      path: 'bindingWechat',
      name: 'bindingWechat',
      component: () => import('@/views/mySetting/bindingWetchat/index.vue'),
      meta: {
        title: '绑定微信'
      }
    }, {
      path: 'bindingMobile',
      name: 'bindingMobile',
      component: () => import('@/views/mySetting/bindingMobile/bindingMobile.vue'),
      meta: {
        title: '绑定手机号'
      }
    }, {
      path: 'settingPassword',
      name: 'settingPassword',
      component: () => import('@/views/mySetting/settingPassword/index.vue'),
      meta: {
        title: '设置密码'
      }
    }],
    meta: {
      title: '我的设置'
    }
  },
  {
    path: '/bookIndex',
    name: 'bookIndex',
    // component: () => import('@/views/portfolio/booksIndex.vue'),
    component: () => import('@/views/portfolio/booksTop.vue'),
    children: [
      {
        path: '/audioBook',
        name: 'audioBook',
        // component: () => import('@/views/portfolio/newAudioBook.vue'),
        component: () => import('@/views/portfolio/audioBook.vue'),
        meta: {
          title: '有声书'
        }
      },
      {
        path: '/ebookDetail',
        name: 'ebookDetail',
        component: () => import('@/views/eBook/ebookDetail.vue'),
        meta: {
          title: '电子书详情'
        },
      },
      {
        path: '/physicalGoods',
        name: 'physicalGoods',
        component: () => import('@/views/physicalGoods/physicalGoods.vue'),
        meta: {
          title: '商品简介'
        }
      },
      {
        path: '/seriesBook',
        name: 'seriesBook',
        component: () => import('@/views/portfolio/seriesBook/seriesBookBox.vue'),
        meta: {
          title: '丛书'
        }
      },
      {
        path: '/relevance',
        name: 'relevance',
        component: () => import('@/views/portfolio/relevance/relevance.vue'),
        meta: {
          title: '关联阅读'
        }
      },
    ],
    meta: {
      title: '书籍'
    }
  },
  // {
  //   path: '/portfolio',
  //   name: 'portfolio',
  //   component: () => import('@/views/portfolio/oldBox/portfolio.vue'),
  //   meta: {
  //     title: '作品集'
  //   }
  // },
  // {
  //   path: '/audioBook',
  //   name: 'audioBook',
  //   component: () => import('@/views/portfolio/audioBook.vue'),
  //   meta: {
  //     title: '有声书'
  //   }
  // },
  {
    path: '/audioCourse',
    name: 'audioCourse',
    component: () => import('@/views/portfolio/newAudioCourse2.vue'),
    meta: {
      title: '课程'
    }
  },
  {
    path: '/audioPodcast',
    name: 'audioPodcast',
    component: () => import('@/views/portfolio/newAudioPodcast2.vue'),
    meta: {
      title: '播客'
    }
  },
  // {
  //   path: '/audioRadio',
  //   name: 'audioRadio',
  //   component: () => import('@/views/portfolio/oldBox/audioRadio.vue'),
  //   meta: {
  //     title: '电台'
  //   }
  // },
  {
    path: '/voiceDetail',
    name: 'voiceDetail',
    component: () => import('@/views/voiceDetail/index.vue'),
    meta: {
      title: '作品'
    }
  },
  {
    path: '/audioBookDetail',
    name: 'audioBookDetail',
    component: () => import('@/views/voiceDetail/audioBookDetail2.vue'),
    meta: {
      title: '有声书详情'
    }
  },
  {
    path: '/audioCourseDetail',
    name: 'audioCourseDetail',
    component: () => import('@/views/voiceDetail/audioCourseDetail.vue'),
    meta: {
      title: '课程详情'
    }
  },
  {
    path: '/audioPodcastDetail',
    name: 'audioPodcastDetail',
    component: () => import('@/views/voiceDetail/audioPodcastDetail.vue'),
    meta: {
      title: '播客详情'
    }
  },
  {
    path: '/posterDetail',
    name: 'posterDetail',
    component: () => import('@/views/posterDetail/index.vue'),
    meta: {
      title: '海报详情'
    }
  }, {
    path: '/wxAutoRegister',
    name: 'wxAutoRegister',
    component: () => import('@/views/wXauthentication/wxAutoRegister.vue'),
    meta: {
      title: '微信自动注册中间页'
    }
  },
  // {
  //   path: '/physicalGoods',
  //   name: 'physicalGoods',
  //   component: () => import('@/views/physicalGoods/physicalGoods.vue'),
  //   meta: {
  //     title: '商品简介'
  //   }
  // },
  {
    path: '/receiveAward',
    name: 'receiveAward',
    component: () => import('@/views/receiveAward/newReceiveAward.vue'),
    meta: {
      title: '领取礼品'
    },
  }, {
    path: '/category',
    name: 'category',
    component: () => import('@/views/category/index_new.vue'),
    meta: {
      title: '分类'
    },
  },
  // {
  //   path: '/ebookDetail',
  //   name: 'ebookDetail',
  //   component: () => import('@/views/eBook/ebookDetail.vue'),
  //   meta: {
  //     title: '电子书详情'
  //   },
  // },
  {
    path: '/ebookReader',
    name: 'ebookReader',
    component: () => import('@/views/eBook/ebookReader.vue'),
    meta: {
      title: '电子书阅读器'
    },
  },
  {
    path: '/questionsAnswers',
    name: 'questionsAnswers',
    component: () => import('@/views/questionsAnswers/index.vue'),
    meta: {
      title: '自测'
    },
  },
  {
    path: '/businessHomePage',
    name: 'businessHomePage',
    component: () => import('@/views/businessHomePage/newIndex.vue'),
    meta: {
      title: '出版社主页'
    },
  },
  {
    path: '/variety',
    name: 'variety',
    component: () => import('@/views/variety/index_new.vue'),
    meta: {
      title: '品种'
    },
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: () => import('@/views/distribution/newIndex.vue'),
    meta: {
      title: '分销包'
    },
  },
  {
    path: '/couponPackage',
    name: 'couponPackage',
    component: () => import('@/views/portfolio/couponPackage/index.vue'),
    meta: {
      title: '套包'
    },
  },
  {
    path: '/testHome',
    name: 'testHome',
    component: () => import('@/views/newTestBox/home.vue'),
    meta: {
      title: ' '
    },
  },
  {
    path: '/testDetail',
    name: 'testDetail',
    component: () => import('@/views/newTestBox/detail.vue'),
    meta: {
      title: ' '
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
