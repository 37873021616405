import Vue from 'vue'
import Vuex from 'vuex'
import { get, post, removeToken, getToken } from '@/service/httpService'
import remAutoAdjust from '@/service/remAutoAdjust.js'
import { isWxMobileBrowser, isWxIosMobileBroswer, suspendedPlayerMode } from '@/service/commonService'
const localStorageVersionTime = process.env.VUE_APP_VERSIONTIME; // 本地存储版本时间
Vue.use(Vuex)
const defaultPlayMode = 'voice';  // 默认的作品播放模式
const defaultPosterPreviewMode = 'poster'; // 默认海报预览模式
const defaultDesignWidthDatum = 375; // 默认设计稿基准宽度
const defaulMessage = 0 // 新消息数量
export default new Vuex.Store({
  state: {
    userInfo: (() => {  // 当前用户信息
      let userInfo;
      try {
        userInfo = JSON.parse(window.localStorage.getItem('hmuUserInfo'));
      } catch {
        userInfo = {};
      }
      if (userInfo && userInfo.versionTime === localStorageVersionTime) {
        return userInfo
      } else {
        return {}
      }
    })(),
    lastExpireSMSTime: (() => {  // 上次获取验证码的时间
      let humLastExpireSMSTime = window.localStorage.getItem(
        "humLastExpireSMSTime"
      );
      try {
        humLastExpireSMSTime = JSON.parse(humLastExpireSMSTime);
      } catch {
        humLastExpireSMSTime = {};
      }
      if (!humLastExpireSMSTime || humLastExpireSMSTime.versionTime !== localStorageVersionTime) {
        return {}
      }
      return humLastExpireSMSTime
    })(),
    portfilioSortTypes: (() => {   // 作品集的排序状态本地存储
      let hmuPortfilioSortTypes = window.localStorage.getItem('hmuPortfilioSortTypes');
      try {
        hmuPortfilioSortTypes = JSON.parse(hmuPortfilioSortTypes);
      } catch {
        hmuPortfilioSortTypes = {};
      }
      if (hmuPortfilioSortTypes && hmuPortfilioSortTypes.versionTime === localStorageVersionTime) {
        return hmuPortfilioSortTypes;
      } else {
        return {}
      }
    })(), // 作品集列表的排序方式
    voicePlayMode: (() => {
      let hmuVoicePlayMode = window.localStorage.getItem('hmuVoicePlayMode');
      try {
        hmuVoicePlayMode = JSON.parse(hmuVoicePlayMode)
      } catch {
        hmuVoicePlayMode = {};
      }
      if (!hmuVoicePlayMode || hmuVoicePlayMode.versionTime !== localStorageVersionTime) {
        return {
          mode: defaultPlayMode,
        }
      } else {
        return hmuVoicePlayMode
      }
    })(), // 音频播放模式 voice为音频模式  text 为文字模式
    posterPreviewMode: (() => {
      let hmuPosterPreviewMode = window.localStorage.getItem('hmuPosterPreviewMode');
      try {
        hmuPosterPreviewMode = JSON.parse(hmuPosterPreviewMode)
      } catch {
        hmuPosterPreviewMode = {};
      }
      if (!hmuPosterPreviewMode || hmuPosterPreviewMode.versionTime !== localStorageVersionTime) {
        return {
          mode: defaultPosterPreviewMode,
        }
      } else {
        return hmuPosterPreviewMode
      }
    })(), // 海报预览模式， poster为海报模式 text 为文字模式
    primaryColor: '#FCD006', // 主色调  js用
    playingAudio: (() => {
      let humPlayingAudio = window.localStorage.getItem('humPlayingAudio');
      if (!humPlayingAudio || (isWxMobileBrowser() && suspendedPlayerMode() === 2)) {
        return null;
      }
      humPlayingAudio = JSON.parse(humPlayingAudio);
      if (humPlayingAudio.versionTime !== localStorageVersionTime) {
        return null
      }
      const audioDom = document.createElement('audio');
      audioDom.src = humPlayingAudio.audioSrc;

      humPlayingAudio.audioDom = audioDom;
      const currentTime = (humPlayingAudio.currentTime === undefined) ? 0 : Number(humPlayingAudio.currentTime) + 0.2;
      if (humPlayingAudio.status === 'playing') {
        if (isWxMobileBrowser()) {
          console.log('ready');
          document.addEventListener("WeixinJSBridgeReady", function () {  // 兼容ios端打开页面自动播放音频
            if (isWxIosMobileBroswer()) {
              console.log('iosplay');
              humPlayingAudio.audioDom.oncanplay = function () {
                console.log('ios');
                audioDom.currentTime = currentTime;
              };
              humPlayingAudio.audioDom.play().catch((e) => {
                humPlayingAudio.status = 'pause';
                console.error(e);
              });
            } else {
              console.log('androidplay');
              audioDom.currentTime = currentTime;
              humPlayingAudio.audioDom.play().catch((e) => {
                humPlayingAudio.status = 'pause';
                console.error(e);
              });
            }
            /* } */
          }, false);
        } else {
          audioDom.currentTime = currentTime;
          humPlayingAudio.audioDom.play().catch((e) => {
            humPlayingAudio.status = 'pause';
            console.error(e);
          });
        }
      } else {
        if (isWxMobileBrowser()) {
          document.addEventListener("WeixinJSBridgeReady", function () {  // 兼容ios端打开页面设置播放时间
            console.log('ready');
            if (isWxIosMobileBroswer()) {
              console.log('iospause');
              humPlayingAudio.audioDom.oncanplay = function () {
                console.log('ios');
                audioDom.currentTime = currentTime;
              };
            } else {
              console.log('androidplay');
              audioDom.currentTime = currentTime;
            }
          });
        } else {
          audioDom.currentTime = currentTime;
        }
      }
      return humPlayingAudio
    })(),  // 正在播放的音频
    suspendedPlayerY: (() => { // 悬浮音频播放器的y坐标
      let humSuspendedPlayerY = window.localStorage.getItem('humSuspendedPlayerY');
      try {
        humSuspendedPlayerY = JSON.parse(humSuspendedPlayerY)
      } catch {
        humSuspendedPlayerY = {}
      }
      if (!humSuspendedPlayerY || humSuspendedPlayerY.versionTime !== localStorageVersionTime) {
        return {};
      } else {
        return humSuspendedPlayerY;
      }
    })(),
    designWidthDatum: (() => {
      let humDesignWidthDatumStorage = window.localStorage.getItem('humDesignWidthDatum');
      try {
        humDesignWidthDatumStorage = JSON.parse(humDesignWidthDatumStorage);
      } catch {
        humDesignWidthDatumStorage = {};
      }
      if (!humDesignWidthDatumStorage || humDesignWidthDatumStorage.versionTime !== localStorageVersionTime) {
        return {
          value: defaultDesignWidthDatum
        }
      } else {
        return humDesignWidthDatumStorage
      }
    })(),
    newMessages: (() => {
      let humNewMessages = window.localStorage.getItem('humNewMessages');
      if (!humNewMessages) {
        return defaulMessage
      } else {
        return humNewMessages
      }
    })()
  },
  getters: {
    /**
     * @description: 获取上次获取验证码的时间
     * @param {*} state
     * @return {*}
     */
    lastExpireSMSTime(state) {
      return state.lastExpireSMSTime.time === undefined ? 0 : state.lastExpireSMSTime.time;
    },
    /**
     * @description: 获取用户信息
     * @param {*} state
     * @return {*}
     */
    userInfo(state) {
      return state.userInfo;
    },
    /**
     * @description: 获取悬浮播放器的y轴坐标
     * @param undefined
     * @return {*}
     */
    suspendedPlayerY(state) {
      return state.suspendedPlayerY.value;
    },
    /**
     * @description: 获取正在播放的音频
     * @return {*}
     */
    playingAudio(state) {
      return state.playingAudio
    },
    /**
     * @description: 根据声音id，获取正在播放的音频,如果正在播放的音频与id匹配成功，则直接播放
     * @return {*}
     */
    getPlayingAudioById(state) {
      return (id) => {
        const playingAudio = state.playingAudio;
        if (!playingAudio) {
          return
        }
        if (Number(playingAudio.id) !== Number(id)) {
          return;
        }
        if (!playingAudio.audioDom) {
          playingAudio.audioDom = document.createElement('audio');
          playingAudio.audioDom.src = playingAudio.audioSrc;
          playingAudio.audioDom.currentTime = playingAudio.currentTime;
        }
        return playingAudio;
      }
    },
    /**
     * @description: 获取主色调
     * @param {*} state
     * @return {*}
     */
    primaryColor(state) {
      return state.primaryColor;
    },
    /**
     * @description: 当前用户手机号
     * @param {*} state
     * @return {*}
     */
    mobile(state) {
      return state.userInfo && state.userInfo.phoneNumber;
    },
    /**
     * @description: 海报预览模式
     * @param {*}
     * @return {*}
     */
    posterPreviewMode(state) {
      return state.posterPreviewMode.mode;
    },
    /**
     * @description: 播放模式
     * @param {*}
     * @return {*}
     */
    voicePlayMode(state) {
      return state.voicePlayMode.mode
    },
    /**
     * @description: 是否是登录状态
     * @param {*}
     * @return {*}
     */
    isLogin(state) {
      return JSON.stringify(state.userInfo) !== '{}';
    },
    /**
     * @description: 获取当前用户的注册状态
     * @return {*}
     */
    registerStatus(state, getters) {
      if (!getters.isLogin) {
        return 0
      }
      const { registerStatus } = state.userInfo;
      return registerStatus === undefined ? 0 : registerStatus;
    },
    /**
     * @description: 设计稿基准尺寸
     * @param {*} state
     * @return {*}
     */
    designWidthDatum(state) {
      return state.designWidthDatum.value;
    },
    /**
     * @description: 获取作品集的排序方式
     * @param {*} state
     * @return {*}
     */
    portfilioSortTypes(state) {
      return portfolioId => {
        portfolioId = portfolioId.toString();
        let sortType = state.portfilioSortTypes[portfolioId];
        sortType = sortType === undefined ? 0 : sortType;
        return sortType
      }
    },
    /**
     * @description: 新消息数量
     * @param {*} state
     * @return {*}
     */
    newMessages(state) {
      return state.newMessages;
    },
  },
  mutations: {
    /**
     * @description: 设置上次获取验证码的时间
     * @param {*} state
     * @param {*} value
     * @return {*}
     */
    setLastExpireSMSTime(state, value) {
      state.lastExpireSMSTime = {
        time: value,
        versionTime: localStorageVersionTime
      }
      window.localStorage.setItem(
        "humLastExpireSMSTime",
        JSON.stringify(state.lastExpireSMSTime)
      );
    },
    /**
     * @description: 更新悬浮播放器的y轴坐标
     * @param undefined
     * @param undefined
     * @return {*}
     */
    updateSuspendedPlayerY(state, value) {
      state.suspendedPlayerY = {
        value,
        versionTime: localStorageVersionTime
      }
      window.localStorage.setItem('humSuspendedPlayerY', JSON.stringify(state.suspendedPlayerY));
    },
    /**
     * @description: 设置正在播放的音频
     * @param state
     * @param playingAudio
     * @return {*}
     */
    setPlayingAudio(state, playingAudio) {
      if (!playingAudio) {
        if (state.playingAudio && state.playingAudio.audioDom) {
          state.playingAudio.audioDom.pause();
        }
        state.playingAudio = null;
        window.localStorage.removeItem('humPlayingAudio');
        return;
      }
      playingAudio.show = playingAudio.show === undefined ? true : playingAudio.show;
      playingAudio.versionTime = localStorageVersionTime;
      const thePlayingAudio = state.playingAudio ? state.playingAudio : {}
      state.playingAudio = Object.assign(thePlayingAudio, playingAudio);
      const humPlayingAudio = {};
      for (let key in state.playingAudio) {
        const value = state.playingAudio[key];
        if (key === 'audioDom') {
          continue;
        }
        humPlayingAudio[key] = value;
      }
      window.localStorage.setItem('humPlayingAudio', JSON.stringify(humPlayingAudio));
    },
    /**
     * @description: 播放音频
     * @return {*}
     */
    playAudio(state, { audioDom, reset }) {
      if (state.playingAudio && state.playingAudio.audioDom) {
        if (reset) {
          state.playingAudio.audioDom.currentTime = 0;
        }
        state.playingAudio.audioDom.pause();
      }
      audioDom.play();
    },
    /**
     * @description: 设置用户手机号
     * @param {*} state
     * @param {*} mobile
     * @return {*}
     */
    setMobile(state, mobile) {
      mobile = mobile.split('').map((item, index) => {
        if (index >= 3 && index <= 6) {
          return '*'
        }
        return item;
      }).join('');
      state.userInfo.phoneNumber = mobile;
      state.userInfo = { ...state.userInfo };
      this.commit('setUserInfo', state.userInfo);
    },
    /**
     * @description: 设置声音播放模式
     * @param {*} state
     * @return {*}
     */
    setPosterPreviewMode(state, posterPreviewMode) {
      state.posterPreviewMode = {
        mode: posterPreviewMode,
        versionTime: localStorageVersionTime
      };
      window.localStorage.setItem('hmuPosterPreviewMode', JSON.stringify(state.posterPreviewMode));
    },
    /**
     * @description: 设置声音播放模式
     * @param {*} state
     * @return {*}
     */
    setVoicePlayMode(state, voicePlayMode) {
      state.voicePlayMode = {
        mode: voicePlayMode,
        versionTime: localStorageVersionTime
      };
      window.localStorage.setItem('hmuVoicePlayMode', JSON.stringify(state.voicePlayMode));
    },
    /**
     * @description: 初始化设计稿基准字体
     * @param {*}
     * @return {*}
     */
    initDesignWidthDatum(state) {
      remAutoAdjust(state.designWidthDatum.value);
    },
    /**
     * @description: 设置设计稿基准尺寸
     * @param {*}
     * @return {*}
     */
    setDesignWidthDatum(state, designWidthDatum) {
      state.designWidthDatum = {
        value: designWidthDatum,
        versionTime: localStorageVersionTime
      };
      window.localStorage.setItem('humDesignWidthDatum', JSON.stringify(state.designWidthDatum));
      remAutoAdjust(designWidthDatum);
    },
    /**
     * @description: 设置用户信息
     * @param {*}
     * @return {*}
     */
    setUserInfo(state, userInfo) {
      if (!userInfo) {
        state.userInfo = {};
        window.localStorage.removeItem('hmuUserInfo');
        return;
      }
      userInfo.versionTime = localStorageVersionTime;
      state.userInfo = userInfo;
      const userInfoStr = JSON.stringify(userInfo);
      if (userInfoStr === '{}') {
        window.localStorage.removeItem('hmuUserInfo');
      } else {
        window.localStorage.setItem('hmuUserInfo', JSON.stringify(userInfo));
      }
    },
    /**
     * @description: 退出登录
     * @param {*} state
     * @return {*}
     */
    logout(state) {
      state.userInfo = {};
      window.localStorage.removeItem('humUserInfo');
      removeToken();
    },
    /**
     * @description: 设置作品集的排序方式
     * @param {*} state
     * @param {*} portfolioId
     * @param {*} sortType
     * @return {*}
     */
    setPortfilioSortType(state, { portfolioId, sortType }) {
      portfolioId = portfolioId.toString();
      state.portfilioSortTypes[portfolioId] = sortType;
      state.portfilioSortTypes.versionTime = localStorageVersionTime;
      state.portfilioSortTypes = { ...state.portfilioSortTypes };
      window.localStorage.setItem('hmuPortfilioSortTypes', JSON.stringify(state.portfilioSortTypes));
    },
    /**
     * @description: 新消息数量
     * @param {*} state
     * @param {*} value
     * @return {*}
     */
    setNewMsgNub(state, value) {
      state.nub = value
      window.localStorage.setItem(
        "humNewMessages", state.nub
      );
    },
  },
  actions: {
    /**
     * @description: 获取用户信息
     * @param {*}
     * @return {*}
     */
    async getUserInfo({ getters }) {
      const hToken = getToken();
      if (!hToken) {
        return;
      }
      let userInfo = getters.userInfo;
      if (JSON.stringify(userInfo) === '{}') {
        userInfo = await get('/user/info', {}, true).catch(() => { });
      }
      if (userInfo) {
        this.commit('setUserInfo', userInfo);
        return userInfo;
      }
    },
    /**
     * @description: 退出登录
     * @param {*}
     * @return {*}
     */
    async logout({ commit }) {
      await post('/user/logout', {}, true, false, true).catch(() => { })
      commit('logout')
    }
  },
  modules: {
  }
})
