/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-03-19 22:08:54
 * @LastEditors: 白志明
 * @LastEditTime: 2022-03-27 21:50:45
 */
import Vue from 'vue';
import { Form, Field, Button, Checkbox, Col, Row, Search } from 'vant';
import commonBottomNav from './commonBottomNav.vue';  // 通用底部导航
import commonSearch from './commonSearch.vue' // 通用检索框
import loaders from './loaders/loaders.vue' // 通用loaders组件
import commonCommentList from './commonCommentList.vue'; // 通用评论列表页

Vue.component('Form', Form);
Vue.component('Field', Field);
Vue.component('Button', Button);
Vue.component('Checkbox', Checkbox);
Vue.component('Col', Col);
Vue.component('Row', Row);
Vue.component('Search', Search);
Vue.component('commonBottomNav', commonBottomNav);
Vue.component('commonSearch', commonSearch);
Vue.component('loaders', loaders);
Vue.component('commonCommentList', commonCommentList);