/*
 * @Author: pikachu
 * @Date: 2022-08-28 19:53:25
 * @LastEditors: pikachu
 */
import Vue from 'vue'
Vue.component('remote-res', {
    data() {
        return {
            loaded: false
        };
    },
    render(createElement) {
        let scs = [];
        if (this.css) {
            for (let i = 0; i < this.css.length; i++) {
                let pps = {
                    attrs: {
                        rel: 'stylesheet',
                        href: this.css[i]
                    }
                };
                scs.push(createElement('link', pps));
            }
        }
        // if (this.vif && !this.vif()) {
        //     if (!this.loaded) {
        //         this.loaded = true;
        //         if (this.scripts) {
        //             for (let i = 0; i < this.scripts.length; i++) {
        //                 let pps = {
        //                     attrs: {
        //                         type: 'text/javascript',
        //                         src: this.scripts[i]
        //                     }
        //                 };
        //                 if (i === this.scripts.length - 1) {
        //                     pps.on = {
        //                         load: () => {
        //                             this.$emit("loaded");
        //                         }
        //                     };
        //                 }
        //                 scs.push(createElement('script', pps));
        //             }
        //         }
        //     }
        // } else {
        //     if (!this.loaded) {
        //         this.loaded = true;
        //         this.$emit("loaded");
        //     }
        // }
        return createElement('div', scs);
    },
    props: {
        // vif: {
        //     type: Function,
        //     required: true
        // },
        // scripts: {
        //     type: Array
        // },
        css: {
            type: Array
        },
    },
})

Vue.prototype.$postMark = async function (type, dataId) {
    await Vue.prototype.get("/page/save", {
        dataType: type,
        dataId: dataId,
    });
}