/*
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2022-03-24 17:15:11
 * @LastEditors: pikachu
 * @LastEditTime: 2022-11-08 17:21:56
 */
import Vue from 'vue';
import { get, setToken, getToken, post } from '@/service/httpService'
import dayjs from "dayjs";
import { Toast } from "vant";
import store from '@/store'

/**
 * @description: 标红命中词
 * @param {*}
 * @return {*}
 */
export function markedRed(content, keyword) {
  if (!keyword) {
    return content;
  }
  const reg = new RegExp(keyword, 'g');
  return content.replace(reg, (matchCode) => {
    return `<font color="red">${matchCode}</font>`
  });
}
/**
 * @description: 放入原型链，方便组件中调用，但不推荐
 * @param {*}
 * @return {*}
 */
Vue.prototype.markedRed = markedRed
/**
 * @description: 初始化vconsole
 * @param {*}
 * @return {*}
 */
export async function initVconsole({
  debuggerMode = false
}) {
  if (!debuggerMode) {
    return;
  }
  let vconsole = document.querySelector('#__vconsole')
  if (vconsole) {
    return;
  }
  const VConsoleModule = () => import('vconsole');
  let VConsole = await VConsoleModule();
  VConsole = VConsole.default;
  new VConsole();
  /* vconsole = document.querySelector('#__vconsole');
  vconsole.style.display = 'none';
  let vconsoleShow = false;
  let clickDelayTimer;
  let clickTime = 0;
  document.body.addEventListener('click', () => {
    if (clickDelayTimer) {
      clearTimeout(clickDelayTimer);
      clickDelayTimer = '';
      clickTime++;
      if (clickTime <= 6) {
        return;
      }
      if (!vconsoleShow) {
        vconsole.style.display = '';
        vconsoleShow = true;
      } else {
        vconsole.style.display = 'none';
        vconsoleShow = false;
      }
      clickTime = 0;
    }
    clickDelayTimer = setTimeout(() => {
      clickTime = 0;
    }, 1000);
  }); */
}
/**
 * @description: 加载sdk
 * @param {*}
 * @return {*}
 */
export function loadScript({
  src,
  id
}) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`#${id}`)) {
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.head.append(script);
    script.onload = function () {
      resolve();
    }
    script.onerror = function () {
      reject()
    }
  });
}
/**
 * @description: 获取浏览器版本
 * @param {*}
 * @return {*}
 */
export function getBrowserInfo() {
  const browser = {
    versions: function () {
      var u = navigator.userAgent;
      return { //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
      };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase()
  }
  var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //在微信中打开
    var isComWx = /wxwork/i.test(navigator.userAgent); // 是否企业微信
    if (isComWx) {
      browser.app = 'wxwork'
    } else {
      browser.app = 'weixin'
    }
  }
  if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
    if (ua.match(/WeiBo/i) == "weibo") {
      //在新浪微博客户端打开
      browser.app = 'weibo'
    }
    else if (ua.match(/QQ/i) == "qq") {
      //在QQ空间打开
      browser.app = 'qq'
    }
  } else {
    //否则就是PC浏览器打开
    browser.versions.pc = true;
  }
  return browser;
}
/**
 * @description: 判断当前环境是否是微信
 * @param {*}
 * @return {*}
 */
export function isWxBrowser() {
  const browserInfo = getBrowserInfo();
  return browserInfo.app === 'weixin'
}
/**
 * @description: 判断当前环境是否是企业微信
 * @param {*}
 * @return {*}
 */
export function isWorkWxBrowser() {
  const browserInfo = getBrowserInfo();
  return browserInfo.app === 'wxwork'
}
/**
 * @description: 判断当前环境是否是微信手机端环境
 * @param {*}
 * @return {*}
 */
export function isWxMobileBrowser() {
  const browserInfo = getBrowserInfo();
  return browserInfo.app === 'weixin' && browserInfo.versions.mobile === true
}
/**
 * @description: 是否是ios 微信移动端
 * @return {*}
 */
export function isWxIosMobileBroswer() {
  if (!isWxMobileBrowser()) {
    return false;
  }
  const browserInfo = getBrowserInfo();
  if (!browserInfo.versions.ios) {
    return false;
  }
  return true;
}
/**
 * @description: 是否是wap环境，非微信的移动端浏览器
 * @return {*}
 */
export function isWapBroswer() {
  const browserInfo = getBrowserInfo();
  return browserInfo.app !== 'weixin' && browserInfo.versions === 'mobile'
}
/**
 * @description: 悬浮的播放器模式  1： 所有环境，播放模式统一,不使用微信开放标签   2：除了微信环境仍然使用开放标签，但没有自定义的悬浮组件。其他环境均支持悬浮音频播放器
 * @return {*}
 */
export function suspendedPlayerMode() {
  return 1
}
/**
 * @description: 微信自动登录
 * @param {*}
 * @return {*}
 */
export async function wxAutoLogin(route, router) {
  const code = route?.query?.code;
  if (!isWxBrowser()) {
    return;
  }
  // const hToken = getToken();
  // if (hToken) {
  //   return;
  // }
  await window.wxSdkConfigPromise;
  if (code) {
    let codeStatus = localStorage.getItem(code)
    if (codeStatus) {
      let parseCodeStatus = JSON.parse(codeStatus)
      if (parseCodeStatus.code == code && Date.now() > parseCodeStatus.time) return
    }
    let userInfo = await store.dispatch('getUserInfo').catch(() => { });
    let resp
    if (userInfo && userInfo.registerStatus === 0) {
      // 授权
      resp = await post(`/user/registerWc/${code}`).catch(() => { });
    } else {
      // 登录
      const loading = Toast.loading({
        message: '初始化信息中，请稍候...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      resp = await post(`/user/loginWc/${code}`).catch(
        () => { }
      );
      loading.clear();
    }
    if (!resp) {
      Toast({
        type: "info",
        message: "查询当前token失败，请刷新页面后重试",
        duration: 1e3,
      });
      window.localStorage.removeItem('h_token');
    } else {
      store.commit("setUserInfo", null);
      await store.dispatch("getUserInfo");
      setToken(resp);
      localStorage.setItem('code', JSON.stringify({ code: route.query.code, time: Date.now() }))
      delete route.query.code;
      // router.replace2(route);  // 隐藏掉路由上的code码
    }
  } else {
    let href = encodeURIComponent(window.location.href, 'UTF-8')
    const wxAuthorAddress = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appId}&redirect_uri=${href}&response_type=code&scope=snsapi_base&state=base#wechat_redirect`;
    window.location.replace(wxAuthorAddress);
  }
}
/**
 * @description: 微信环境自动注册功能
 * @param {*}
 * @return {*}
 */
export async function wxAutoRegister(router) {
  /*  const { query } = route;
   const code = query.code; */
  if (!isWxBrowser()) {
    Toast({
      type: "info",
      message: "该环境不支持自动注册，请先用微信扫描二维码自动注册账户",
      duration: 1e3,
    });
    return;
  }
  await window.wxSdkConfigPromise;
  /* if (code) {
    const loading = Toast.loading({
      message: '注册用户信息中，请稍候...',
      forbidClick: true,
      loadingType: 'spinner',
    });
    const resp = await post(`/user/registerWc/${code}`).catch(
      () => { }
    );
    if (!resp) {
      Toast({
        type: "info",
        message: "查询当前token失败，请刷新页面后重试",
        duration: 1e3,
      });
    } else {
      setToken(resp);
      store.commit('setUserInfo', null);
      await store.dispatch('getUserInfo');
      delete route.query.code;
      const href = router.resolve(route).href;  // 隐藏掉路由上的code码
      window.location.replace(href);
      loading.clear();
    }
  } else { */
  // const href = `${window.location.origin}${router.resolve({
  //   name: 'wxAutoRegister',
  //   query: {
  //     beforeAddress: window.location.href
  //   }
  // }).href}`
  const href = window.location.href
  let keyValue = href.split('?')[1]?.split('&')
  let obj = {};
  for (let i = 0; i < keyValue.length; i++) {
    let item = keyValue[i].split('=');
    let key = item[0];
    let value = item[1];
    obj[key] = value;
  }
  obj.code ? delete obj.code : ''
  let keys = Object.keys(obj)
  let urlParam = ''
  keys.forEach(e => {
    urlParam = `${urlParam}&${e}=${obj[e]}`
  })
  const backLocation = `${window.location.origin}${window.location.pathname}?${urlParam}`
  const wxAuthorAddress = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.appId}&redirect_uri=${window.encodeURIComponent(backLocation)}&response_type=code&scope=snsapi_userinfo&state=userinfo#wechat_redirect`;
  window.location.replace(wxAuthorAddress);
  /* } */
}
/**
 * @description: 获取微信签名相关信息
 * @param {*}
 * @return {*}
 */
export function getWxsign(url = window.location.href) {
  return get('/wxsign', {
    url
  })
}
/**
 * @description: 获取网站应用微信签名相关信息
 * @param {*}
 * @return {*}
 */
export function getWxsignPc(url = window.location.href) {
  return get('/wxsign/pc', {
    url
  })
}
/**
 * @description: 初始化微信相关工具
 * @param {*}
 * @return {*}
 */
export function initWxRelated({ debuggerMode = false }) {
  let isWx = isWxBrowser();
  let isWorkWx = isWorkWxBrowser()
  if (!isWx && !isWorkWx) {
    return;
  }
  // eslint-disable-next-line no-async-promise-executor
  window.wxSdkConfigPromise = new Promise(async (resolve) => {
    await loadScript({
      src: 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js',
      id: 'wxScript'
    });
    const { appId,
      signature,
      nonceStr,
      timestamp } = await getWxsign(window.location.href)
    window.wx.config({
      debug: debuggerMode, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature,// 必填，签名
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'previewImage', 'chooseWXPay', 'scanQRCode', 'startRecord', 'stopRecord', 'translateVoice'], // 必填，需要使用的JS接口列表
      openTagList: ['wx-open-audio'], // 微信开放标签
    });
    window.appId = appId;
    let delayTimer;
    let delayTime = 5e3;
    window.wx.ready(function () {
      clearTimeout(delayTimer);
      resolve({
        appId
      });
      window.wx.checkJsApi({
        jsApiList: ['updateAppMessageShareData'],
        success(res) {
          console.info(res);
        }
      });
    });
    window.wx.error(res => {
      clearTimeout(delayTimer);
      console.error('微信初始化错误');
      console.error(JSON.stringify(res));
    });
    delayTimer = setTimeout(() => {
      resolve({
        appId
      });
    }, delayTime);
  });
}
/**
 * @description: 时间转换,将毫秒转换未 HH:mm:ss格式
 * @param {*}
 * @return {*}
 */
export function durationFormat(duration, fromat = "HH:mm:ss", timeUnit = "s") {
  if (duration === undefined) {
    return "00:00:00";
  }
  duration = (timeUnit == "ms" ? duration : duration * 1e3)
  let time =
    Number(new Date(duration)) +
    Number(new Date(dayjs(new Date()).format("YYYY/MM/DD 00:00:00")));
  time = dayjs(time).format(fromat);
  return time;
}
/**
 * @description: 时间格式化
 * @param {*}
 * @return {*}
 */
export function timeFormat(time) {
  time = time.replace(/-/g, '/');
  const now = new Date();
  let format = 'YYYY-MM-DD'
  let theTime = dayjs(time).format(format);
  if (theTime === dayjs(now).format(format)) {
    return dayjs(time).format('HH:mm');
  }
  format = 'YYYY';
  theTime = dayjs(time).format(format);
  if (theTime === dayjs(now).format(format)) {
    return dayjs(time).format('MM-DD HH:mm');
  } else {
    return dayjs(time).format('YYYY-MM-DD HH:mm');
  }
}
