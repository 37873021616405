/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-04-23 18:01:45
 * @LastEditors: pikachu
 * @LastEditTime: 2022-12-21 14:22:20
 */
import { isWxMobileBrowser } from '@/service/commonService'
const pageReloadSwitch = false;  // 路由跳转时，页面重载开关
let pageReloadPush2Replace2Switch = isWxMobileBrowser() ? true : false; // 路由跳转时，push2和replace2的页面重载开关
/**
 * @description: 重写路由方法
 * @param {*} VueRouter
 * @return {*}
 */
export default function rewriteRouter(VueRouter) {
  VueRouter.prototype.orgPush = VueRouter.prototype.push;
  VueRouter.prototype.orgReplace = VueRouter.prototype.replace;
  VueRouter.prototype.push2 = function (location, onComplete, onAbort) {
    let oldUserId = GetQueryString("userId")
    if (oldUserId && location.query) { location.query.userId = oldUserId; location.query.timeStamp = Date.now() } else if (oldUserId && !location.query) { location.query = {}; location.query.userId = oldUserId; location.query.timeStamp = Date.now() }
    if (pageReloadPush2Replace2Switch) {
      const href = this.resolve(location).href;
      window.location.href = href;
    } else {
      return this.orgPush(location, onComplete, onAbort);
    }
  };
  VueRouter.prototype.replace2 = function (location, onComplete, onAbort) {
    let oldUserId = GetQueryString("userId")
    if (oldUserId && location.query) { location.query.userId = oldUserId; location.query.timeStamp = Date.now() } else if (oldUserId && !location.query) { location.query = {}; location.query.userId = oldUserId; location.query.timeStamp = Date.now() }
    if (pageReloadPush2Replace2Switch) {
      const href = this.resolve(location).href;
      window.location.replace(href);
    } else {
      return this.orgReplace(location, onComplete, onAbort);
    }
  }
  if (!pageReloadSwitch) return;
  VueRouter.prototype.push = function (location/* , onComplete, onAbort */) {
    const href = this.resolve(location).href;
    window.location.href = href;
  }
  VueRouter.prototype.replace = function (location/* , onComplete, onAbort */) {
    const href = this.resolve(location).href;
    window.location.replace(href);
  }
}

function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}
