<!--
 * @Author: pikachu
 * @Date: 2022-07-22 15:23:36
 * @LastEditors: pikachu
-->
<template>
  <div class="guide">
    <img class="finger" src="@/assets/finger.png" />
    <div class="toast_text">
      <p>1.点击右上角</p>
      <p>2.点击浮窗方便下次查看</p>
      <div class="ok_bt" @click="closeToast">我知道了</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "guide",
  data() {
    return {};
  },
  methods: {
    closeToast() {
      localStorage.setItem("guideStatus", true);
      this.$emit("closeToast");
    },
  },
};
</script>
<style scoped lang='less'>
.guide {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  .finger {
    transform: rotate(-55deg);
    width: 0.4rem;
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }
  .toast_text {
    color: #fff;
    position: absolute;
    right: 0.1rem;
    top: 0.6rem;
    p {
      animation: text-pop-up-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    .ok_bt {
      border: 0.02rem solid #fff;
      padding: 0.05rem 0.05rem;
      text-align: center;
      margin-top: 0.2rem;
      position: absolute;
      right: 0.2rem;
    }
  }
}
</style>