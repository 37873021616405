/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2020-11-30 13:01:18
 * @LastEditors: 白志明
 * @LastEditTime: 2022-04-03 23:11:56
 */
/**
 * @description: rem字体自动适配
 * @param {*} designWidthDatum  设计稿基准宽度
 * @param {*} pcMobilePartWidth  在pc显示时，移动窗口部分显示宽度
 * @param {*} criticalWidth  在pc端移动端临界宽度
 * @return {*}
 */
let remAutoAdjustBindFn;
function remAutoAdjust(designWidthDatum = 375, pcMobilePartWidth = 375, criticalWidth = 1024) {
  const html = document.documentElement;
  const htmlWidth = html.offsetWidth;
  let clientWidth = htmlWidth > criticalWidth ? pcMobilePartWidth : htmlWidth;
  const fontSize = clientWidth / designWidthDatum * 100 + "px";
  html.style.fontSize = fontSize;
  window.removeEventListener('resize', remAutoAdjustBindFn);
  remAutoAdjustBindFn = remAutoAdjust.bind(this, designWidthDatum, pcMobilePartWidth, criticalWidth);
  window.addEventListener('resize', remAutoAdjustBindFn);
}
export default remAutoAdjust;