<!--
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2022-03-16 20:14:47
 * @LastEditors: pikachu
 * @LastEditTime: 2023-05-11 21:50:27
-->
<template>
  <div class="main_container">
    <div>
      <!-- <headBar v-if="isWxBrowser" /> -->
      <div id="app">
        <!-- <keep-alive>
          <router-view :key="$route.fullPath" class="router_view" v-if="$route.path=='/home'" />
        </keep-alive>-->
        <router-view :key="$route.fullPath" class="router_view" />
        <!-- <keep-alive>
          <router-view class="router_view" />
        </keep-alive>-->
        <suspended-player v-if="suspendedPlayerMode === 1"></suspended-player>
        <!-- <img
          v-if="msgShow"
          class="msg_box"
          :class="msgNew?'slide-in-blurred-bl':''"
          src="@/assets/msg.png"
        />-->
      </div>
    </div>
    <pc-preview></pc-preview>
    <!-- <guide v-if="guideStatus===true" @closeToast="closeToast" /> -->
  </div>
</template>
<script>
import pcPreview from "@/components/pcPreview.vue";
import headBar from "@/components/headBar.vue";
import {
  isWxBrowser,
  suspendedPlayerMode,
  initVconsole,
} from "@/service/commonService";
import suspendedPlayer from "@/components/suspendedPlayer/suspendedPlayer.vue";
import guide from "@/components/guide.vue";
import { getBrowserInfo } from "@/service/commonService.js";
import axios from "axios";

export default {
  components: {
    pcPreview,
    headBar,
    suspendedPlayer,
    guide,
  },
  data() {
    return {
      isWxBrowser: isWxBrowser(),
      suspendedPlayerMode: suspendedPlayerMode(),
      guideStatus: false,
      msgNew: false,
      msgShow: false,
    };
  },
  created() {
    if (getBrowserInfo().versions.pc) {
      localStorage.setItem("guideStatus", true);
    }
    if (localStorage.getItem("guideStatus") != null) {
      this.guideStatus = localStorage.getItem("guideStatus");
    } else {
      setTimeout(() => {
        this.guideStatus = true;
      }, 5000);
    }
    // setTimeout(() => {
    //   this.msgShow = true;
    //   this.msgNew = true;
    //   setTimeout(() => {
    //     this.msgShow = false;
    //   }, 1000);
    // }, 1000);
  },
  mounted() {
    console.log(`当前版本时间为：${process.env.VUE_APP_VERSIONTIME}`);
    initVconsole({ debuggerMode: false });
    // this.getMsgData();
    // document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
    //   WeixinJSBridge.call("hideToolbar");
    // });
  },
  destroyed() {
    // if (this.source) {
    //   this.source.cancel("跳转取消");
    // }
    // window.source.close();
    // axios.get("/h5/sse/closeSseConnect");
  },
  methods: {
    getMsgData() {
      let that = this;
      if (!getToken()) {
        setTimeout(() => {
          that.getMsgData();
        }, 1000);
        return;
      }
      window.source = null;
      // const id = "k000001";
      if (window.EventSource) {
        window.source = new EventSource(
          `/h5/sse/createSseConnect?token=${getToken()}`
        );
        window.source.addEventListener(
          "open",
          function (e) {
            that.setMessageInnerHTML("连接...");
          },
          false
        );
        /**
         * 客户端收到服务器发来的数据
         * 另一种写法：source.onmessage = function (event) {}
         */
        window.source.addEventListener("message", function (e) {
          that.setMessageInnerHTML(e.data);
        });
        /**
         * 如果发生通信错误（比如连接中断），就会触发error事件
         * 另一种写法：source.onerror = function (event) {}
         */
        window.source.addEventListener(
          "error",
          (e) => {
            if (e.readyState === EventSource.CLOSED) {
              that.setMessageInnerHTML("连接关闭");
            } else {
              // that.getMsgData();
            }
          },
          false
        );
      } else {
      }
    },
    setMessageInnerHTML(data) {
      console.log(data);
    },
    closeToast() {
      this.guideStatus = false;
    },
  },
};

/**
 * @description: 从localstorage中获取token
 * @param {*}
 * @return {*}
 */
export function getToken() {
  const hmuToken = window.localStorage.getItem("h_token");
  return hmuToken ? hmuToken : undefined;
}
</script>
<style lang='less'>
@import "./less/public.less";

.main_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  #app {
    overflow-y: auto;
    overflow-x: hidden;
    color: @gray-7;
    background-color: #f2f2f2;
    width: 100vw;
    height: 100vh;
    position: relative;

    & > .router_view {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
.msg_box {
  width: 0.2rem;
  position: absolute;
  right: 0.2rem;
  top: 0.25rem;
  z-index: 1000;
  // background: #fff;
}
.slide-in-blurred-bl {
  -webkit-animation: slide-in-blurred-bl 1s ease-in-out;
  animation: slide-in-blurred-bl 1s ease-in-out;
}

// 渐入
@keyframes slide-in-blurred-bl {
  0% {
    transform: translate(-50vw, 50vh) skew(-0deg, -0deg);
    transform-origin: 100% 100%;
  }
  100% {
    transform: translate(0, 0) skew(0deg, 0deg);
    transform-origin: 50% 50%;
  }
}

@media screen and (min-width: 1024px) {
  .main_container {
    #app {
      width: 430px;
      border: 10px solid black;
      border-color: black;
      border-style: solid;
      border-width: 10px 4px;
      border-radius: 10px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
