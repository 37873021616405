<!--
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2022-03-20 02:35:08
 * @LastEditors: pikachu
 * @LastEditTime: 2022-07-27 21:57:56
-->
<template>
  <div class="common_bottom_nav">
    <div class="common_bottom_nav_inner">
      <Button
        :class="currentName==nav.routeName?'common_bottom_current':''"
        @click="goTo(nav)"
        size="small"
        v-for="nav in data"
        :key="nav.name"
        :text="nav.name"
      ></Button>
    </div>
  </div>
</template>
<script>
export default {
  name: "commonBottomNav",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentName: "",
    };
  },
  created() {
    this.currentName = this.$route.name;
  },
  methods: {
    /**
     * @description: 跳转
     * @param {*}
     * @return {*}
     */
    goTo(nav) {
      this.$router.push2({
        name: nav.routeName,
      });
    },
  },
  mounted() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
.common_bottom_nav {
  width: 100%;
  height: 0.4rem;

  // position: relative;
  .common_bottom_nav_inner {
    border-radius: 0.05rem 0.05rem 0 0;
    padding: 0 0.3rem;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    background-color: @white;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 6px 0 @gray-7;
    bottom: 0;
    z-index: 5;
    button {
      width: 0.8rem;
      flex-shrink: 0;
      background-color: #fff;
      border: #cecece solid 0.01rem;
      color: #666;
      border-radius: 0.15rem;
    }
    /* button + button {
      margin-left: 0.4rem;
    } */
  }
  .common_bottom_current {
    color: #FCD006 !important;
    border-color: #FCD006 !important;
  }
}
@media screen and (min-width: 1024px) {
  .common_bottom_nav {
    .common_bottom_nav_inner {
      width: 422px;
      bottom: 10px;
    }
  }
}
</style>
