/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-03-22 16:34:18
 * @LastEditors: pikachu
 * @LastEditTime: 2022-12-23 22:30:52
 */
import Vue from 'vue';
import axios from 'axios';
import store from '@/store'
import qs from 'qs';
import { Toast, Dialog } from 'vant';
import router from '@/router/index'
import { isWxBrowser, wxAutoLogin, wxAutoRegister } from './commonService';
/**
 * @description: 从localstorage中获取token
 * @param {*}
 * @return {*}
 */
export function getToken() {
  const hmuToken = window.localStorage.getItem('h_token');
  return hmuToken ? hmuToken : undefined;
}
/**
 * @description: 将获取到的token存储到localstorage里
 * @param {*}
 * @return {*}
 */
export function setToken(token) {
  window.localStorage.setItem('h_token', token);
}
/**
 * @description: 删除token
 * @param {*}
 * @return {*}
 */
export function removeToken() {
  window.localStorage.removeItem('h_token');
}
Vue.prototype.get = get;

var CancelToken = axios.CancelToken;
let source = CancelToken.source();
Vue.prototype.source = source

/**
 * @description: get请求
 * @param {*}
 * @return {*}
 */
let urls = ['/h5/notice/noRead']
export function get(url, params, forbidden = false) {
  url = `/h5${url}`;
  return new Promise((resolve, reject) => {
    axios({
      cancelToken: source.token,
      method: "get",
      url: url,
      params,
      headers: {
        h_token: getToken(),
      }
    }).then((resp) => {
      if (resp.data.code === 200) {
        resolve(resp.data.data);
      } else if (resp.data.code === 401) {
        store.commit('logout');
        if (isWxBrowser()) {
          Toast.loading({
            message: '初始化信息中，请稍候...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          wxAutoLogin();
        }
        else if (!forbidden) {
          window.location.replace(`/login?beforeAddress=${window.location.href}`)
        }
        reject(resp.data.msg);
      } else if (resp.data.code === 402) {
        if (isWxBrowser()) {
          const { app: { $router } } = router;
          wxAutoRegister($router);
        } else {
          Toast({
            message: `您无法执行此操作，当前用户为未注册用户，请联系客服处理`,
            type: "fail",
            duration: 5e3,
            position: "top",
          });
        }
      } else if (resp.data.code === 308) {
        Toast({
          message: `${resp.data.msg}`,
          type: "fail",
          duration: 5e3,
          position: "top",
        });
        window.location.href = `/mySetting/bindingMobile?beforeAddress=${window.location.href}`
        reject(resp.data.msg);
      } else {
        if (!forbidden) {
          Toast({
            message: `${resp.data.msg}`,
            type: "fail",
            duration: 5e3,
            position: "top",
          });
        }
        reject(resp.data.msg);
      }
    }, (err) => {
      if (err.message == '跳转取消') return
      let status = false
      urls.forEach(e => {
        if (url == e) status = true
      })
      if (status) return
      if (!forbidden) {
        Toast({
          message: `${err.toString()}`,
          type: "fail",
          duration: 5e3,
          position: "top",
        });
      }
      reject(err);
    });
  });
}
Vue.prototype.post = post;
/**
 * @description: post请求
 * @param {*}
 * @return {*}
 */
export function post(url,
  params,
  applicationJson = true,
  fullUrl = false,
  forbidden = false) {
  return new Promise((resolve, reject) => {
    url = fullUrl ? url : `/h5${url}`
    axios({
      method: "post",
      url: url,
      data: params,
      headers: {
        "Content-Type": applicationJson ? "application/json" : "application/x-www-form-urlencoded",
        h_token: getToken()
      },
      transformRequest: [
        function (data) {
          data = applicationJson ? JSON.stringify(data) : data instanceof FormData ? data : qs.stringify(data);
          return data
        }
      ],
      cancelToken: source.token,
    }).then(
      resp => {
        let logUrlList = ['/share/save', '/page/log']
        let returnData = false
        logUrlList.forEach(e => {
          if (url == `/h5${e}`) returnData = true
        })
        if (returnData) return resolve()
        if (resp.data.code === 200) {
          resolve(resp.data.data);
        } else if (resp.data.code === 401) {
          store.commit('logout');
          if (isWxBrowser()) {
            Toast.loading({
              message: '初始化信息中，请稍候...',
              forbidClick: true,
              loadingType: 'spinner',
            });
            wxAutoLogin();
          }
          else if (!forbidden) {
            if (!isWxBrowser()) {
              window.location.href = `/login?beforeAddress=${window.location.href}`
            }
          }
          reject(resp.data.msg);
        } else if (resp.data.code === 402) {
          if (isWxBrowser()) {
            const { app: { $router } } = router;
            wxAutoRegister($router);
          } else {
            Toast({
              message: `您无法执行此操作，当前用户为未注册用户，请联系客服处理`,
              type: "fail",
              duration: 5e3,
              position: "top",
            });
          }
        } else if (resp.data.code === 308) {
          Dialog.confirm({
            title: '提示',
            message: '请完善手机信息后再发表评论！',
            theme: 'round-button',
          }).then(() => {
            window.location.href = `/mySetting/bindingMobile?beforeAddress=${window.location.href}`
          });
          reject(resp.data.msg);
        } else {
          if (!forbidden) {
            Toast({
              message: `${resp.data.msg}`,
              type: "fail",
              duration: 5e3
            });
          }
          reject(resp.data.msg);
        }
      },
      err => {
        if (err.message == '跳转取消') return
        Toast({
          message: `${err.toString()}`,
          type: "fail",
          duration: 5e3
        });
        reject(err);
      }
    );
  });
}
