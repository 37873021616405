<!--
 * @Descripttion: 通用评论列表组件
 * @version: 
 * @Author: 
 * @Date: 2022-03-26 14:20:45
 * @LastEditors: pikachu
 * @LastEditTime: 2022-06-27 10:38:32
-->
<template>
  <div class="common_comment_list_container">
    <ul v-if="commentList && commentList.length > 0" class="common_comment_list">
      <li v-for="(comment, index) in formartContent(commentList)" :key="comment.id">
        <div class="top">
          <div class="user_name">
            <img :src="comment.fromUserAvatar ? comment.fromUserAvatar : avatarPng" />
            <span v-text="comment.fromUserName"></span>
            <!-- <span class="time" v-text="comment.commentTime"></span> -->
          </div>
          <div v-if="canReply" @click="reply(comment)" class="reply_entry">
            <span>回复</span>
          </div>
        </div>
        <div class="content_box">
          <div
            :class="[comment.status ? 'comment_show' : 'comment']"
            v-text="comment.commentContent"
          ></div>
          <span
            class="read_more"
            v-if="comment.commentContent.length > 70 && !comment.status"
            @click="openComment(index)"
          >更多</span>
        </div>
        <div
          v-if="
          comment.commentChildVOList &&
          comment.commentChildVOList.length !== 0
        "
          class="reply"
        >
          <ul class="common_comment_list">
            <li v-for="(reply, reIndex) in comment.commentChildVOList" :key="reply.id">
              <div class="top">
                <div class="user_name">
                  <img :src="reply.fromUserAvatar ? reply.fromUserAvatar : avatarPng" />
                  <span v-text="`${reply.fromUserName}@${reply.toUserName}`"></span>
                  <!-- <span class="time" v-text="comment.commentTime"></span> -->
                </div>
              </div>
              <div class="content_box">
                <div
                  :class="reply.status ? 'comment_show' : 'comment'"
                  v-text="reply.commentContent"
                ></div>
                <span
                  class="read_more"
                  v-if="reply.commentContent.length > 60 && !reply.status"
                  @click="openComment(index, reIndex)"
                >更多</span>
              </div>
            </li>
          </ul>
          <div
            v-if="comment.pager.total > comment.commentChildVOList.length"
            @click="loadMoreReply(comment)"
            class="load_more_children"
          >
            <span>点击加载更多回复</span>
          </div>
        </div>
      </li>
    </ul>
    <slot name="loader"></slot>
    <Popup v-model="showCommentModal" title="回复评论" show-cancel-button class="comment_modal_box">
      <commentModal
        :key="modalRandom"
        @submit="getCommentContent"
        @cancel="showCommentModal = false;repliedItem = null;"
      ></commentModal>
    </Popup>
    <loaders :loader="loadMoreReplyLoader" tip="获取更多回复中,请稍候..." :smallRange="true"></loaders>
    <loaders :loader="replyCommentLoader" tip="回复评论中,请稍候..." :smallRange="true"></loaders>
  </div>
</template>
<script>
import avatarPng from "@/assets/avatar.png";
import { Dialog, Popup } from "vant";
import commentModal from "@/components/commentModal.vue";
let listIndex = 0;
export default {
  components: {
    commentModal,
    Popup,
  },
  props: {
    commentList: {
      type: Array,
      default() {
        return [];
      },
    },
    canReply: {
      type: Boolean,
      default: false,
    },
  },
  name: "commonCommentList",
  data() {
    return {
      avatarPng,
      loadMoreReplyLoader: null,
      showCommentModal: false,
      commentedItem: null,
      replyCommentLoader: null,
      modalRandom: Math.random(),
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin;
    },
    registerStatus() {
      return this.$store.getters.registerStatus;
    },
    formartContent() {
      return function (list) {
        if (listIndex > 0) return list;
        listIndex++;
        list.forEach((e) => {
          e.status = false;
          if (e.commentChildVOList) {
            e.commentChildVOList.forEach((f) => {
              f.status = false;
            });
          }
        });
        return list;
      };
    },
  },
  methods: {
    /* 评论展开 */
    openComment(index = -1, reIndex = -1) {
      let commentList = JSON.parse(JSON.stringify(this.commentList));
      if (index > -1 && reIndex > -1) {
        commentList[index].commentChildVOList[reIndex].status = true;
        this.$set(this.commentList, index, commentList[index]);
      } else if (index > -1) {
        commentList[index].status = true;
        this.$set(this.commentList, index, commentList[index]);
      }
    },
    /**
     * @description: 获取评论内容
     * @param {*}
     * @return {*}
     */
    async getCommentContent(commentContent) {
      this.replyCommentLoader = this.post("/comment/add", {
        parentId: this.repliedItem.id,
        commentContent,
      });
      await this.replyCommentLoader;
      this.repliedItem.pager.pageNum = 0;
      this.repliedItem.commentChildVOList = [];
      this.repliedItem.pager.total = 0;
      await this.loadMoreReply(this.repliedItem);
      this.repliedItem = null;
      this.showCommentModal = false;
    },
    /**
     * @description: 回复评论
     * @param {*}
     * @return {*}
     */
    async reply(comment) {
      if (!this.isLogin) {
        const flag = await Dialog.confirm({
          title: "登录",
          message: "登录后才能回复评论",
          confirmButtonText: "去登陆",
          cancelButtonText: "下次",
          closeOnPopstate: true,
        }).catch(() => {});
        if (!flag) {
          return;
        }
        this.$router.push2({
          name: "login",
          query: {
            beforeAddress: window.location.href,
          },
        });
        return;
      } else if (this.registerStatus !== 2) {
        this.$router.push2({
          name: "bindingMobile",
          query: {
            beforeAddress: window.location.href,
          },
        });
        return;
      }
      this.showCommentModal = true;
      this.repliedItem = comment;
      this.modalRandom = Math.random();
    },
    /**
     * @description: 加载更多回复
     * @param {*}
     * @return {*}
     */
    async loadMoreReply(comment) {
      const self = this;
      comment.pager.pageNum++;
      self.loadMoreReplyLoader = self.get("/comment/page", {
        pageSize: comment.pager.pageSize,
        pageNum: comment.pager.pageNum,
        parentId: comment.id,
      });
      const {
        rows: commentChildVOList,
        pageNum,
        pageSize,
        total,
      } = await self.loadMoreReplyLoader;
      comment.commentChildVOList = [
        ...comment.commentChildVOList,
        ...commentChildVOList,
      ];
      comment.pager.pageSize = pageSize;
      comment.pager.pageNum = pageNum;
      comment.pager.total = total;
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.common_comment_list_container {
  width: 100%;

  .common_comment_list {
    width: 100%;
    margin-top: 0.1rem;

    & > li {
      padding-bottom: 0.1rem;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        padding: 0 0.05rem;

        .user_name {
          font-size: 0.14rem;
          display: flex;
          align-items: center;

          img {
            width: 0.2rem;
            margin-right: 0.1rem;
            border-radius: 100px;
          }

          .time {
            display: inline-block;
            font-size: 0.1rem;
            margin-left: 0.05rem;
            color: #aaa;
          }
        }

        .reply_entry {
          color: @primarycolor;
          font-size: 0.14rem;
          flex-shrink: 0;
          cursor: pointer;
          color: #999 !important;
        }
      }

      .content_box {
        display: flex;
        position: relative;

        .comment {
          font-size: 0.14rem;
          // text-indent: 2em;
          // line-height: @line-height-lg;
          // letter-spacing: @letter-spacing-md;
          word-break: break-all;
          // text-align: justify;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: justify;
          line-height: 0.22rem;
        }

        .comment_show {
          font-size: 0.14rem;
          // text-indent: 2em;
          line-height: 0.22rem;
          // letter-spacing: @letter-spacing-md;
          word-break: break-all;
          text-align: justify;
        }

        .read_more {
          font-size: 0.14rem;
          // line-height: 0.33rem;
          // letter-spacing: @letter-spacing-md;
          line-height: 0.2rem;
          letter-spacing: 0.035rem;
          color: #999;
          position: absolute;
          background: white;
          bottom: 0;
          right: 0;

          &::before {
            content: "...";
          }
        }
      }

      .reply {
        width: calc(100% - 0.2rem);
        margin-left: 0.2rem;

        .common_comment_list {
          .top {
            .user_name {
              font-size: 0.12rem;
            }
          }

          .comment {
            font-size: 0.14rem;
            line-height: 0.24rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .load_more_children {
          cursor: pointer;
          font-size: 0.12rem;
          width: 100%;
          height: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    li + li {
      border-top: 1px solid @border-color;
      padding-top: 0.1rem;
    }
  }
}
</style>